<!-- APP启动页 -->
<template>
  <div>
    <blockTitle title="APP启动页">
      <template slot="customContent">
        <el-button
            size="small"
            long
            class="ml-4 bg-blue-900 text-gray-50 w-28"
            @click="submit"
        >
          保存提交
        </el-button>
      </template>
    </blockTitle>

    <el-card shadow="never">
      <div class="card-content">
        <el-form
            ref="form"
            :model="form"
            label-width="160px"
            label-position="left"
            size="small"
            class="overflow-auto w-96"
            :rules="rules"
        >
          <el-form-item label="APP启动页" prop="imgUrl">
            <el-upload
                class="h-48 w-48 border-gray-400 border-dashed border"
                action="#"
                :show-file-list="false"
                :http-request="imgUrlUploader"
                accept="image/jpeg,image/png"
            >
              <img
                  class="p-1 object-contain h-48 w-48"
                  v-if="form.imgUrl"
                  :src="form.imgUrl"
                  alt=""
              />
              <i
                  v-else
                  class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"
              ></i>
            </el-upload>
            <div
                class="text-xs leading-6 font-sans antialiased text-gray-400 mt-1 select-none"
            >
              <p>建议上传：</p>
              <p>1. 图片大小：1080PX * 1920PX</p>
              <p>2. 图片格式仅：jpg、png图片格式</p>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less">
</style>
<script type="text/ecmascript-6">
import oss from '@utils/oss'

export default {
  data () {
    return {
      form: {
        imgUrl: ''
      },
      rules: {}
    }
  },
  methods: {
    submit () {
      this.$api.appBootPage.update(this.form).then(res => {
        this.$message.success('保存成功')
      })
    },
    async imgUrlUploader (item) {
      const { file } = item
      if (this.checkFileType(file)) {
        if (file.type.indexOf('image') === -1) {
          this.$message.error('请选择图片文件')
          return false
        }
        this.form.imgUrl = await oss.upload(file)
        this.$refs.form.validateField('imgUrl')
      } else {
        this.$message.error('请上传JPG、JPEG、PNG的图片格式')
      }
    },
    getData () {
      this.$api.appBootPage.select().then(res => {
        this.form = res.data
      })
    },
    checkFileType (file) {
      const fileType = file.type // 获取文件类型
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png'] // 允许的文件类型
      if (validTypes.includes(fileType)) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
